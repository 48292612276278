import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/Layout'
import Sidebar from '../components/Sidebar'

class ContactThanksRoute extends React.Component {
  render() {
    const { title, subtitle } = this.props.data.site.siteMetadata

    return (
      <Layout>
        <div>
          <Helmet>
            <title>{title}</title>
            <meta name="description" content={subtitle} />
          </Helmet>
          <Sidebar {...this.props} />
          <div className="content">
            <div className="content__inner">
              Vielen Dank für das Feedback und das Interesse an unserem Podcast!
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default ContactThanksRoute

export const pageQuery = graphql`
  query ContactThanksQuery {
    site {
      siteMetadata {
        title
        subtitle
        copyright
        copyrightLink
        menu {
          label
          path
        }
        author {
          name
          email
          twitter
          rss
        }
      }
    }
  }
  `
